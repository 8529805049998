<template>
  <div class="account-page">
    <div class="payment-history__list" v-if="claims && claims.length">
      <div class="payment-history" v-for="(claim, i) in claims" :key="i">
        <div class="payment-history__school" v-if="claim.course && claim.course.school">
          <img
            :src="claim.course.school.head_img | image($store.state._env.MEDIA_ENDPOINT)"
            :alt="claim.course.school.head_img | image_alt"
            class="payment-history__school-image"
          />
          <span class="payment-history__school-title">{{ claim.course.school.title }}</span>
        </div>
        <div class="payment-history__title" v-if="claim.course">
          <span>{{ claim.course.title }}</span>
          <span> {{ claim.sum.toLocaleString("ru-RU", { maximumFractionDigits: 0 }) }} ₽ </span>
        </div>
        <div class="payment-history__footer">
          <span class="payment-history__date">{{ claim.created_at | humanDate }}</span>
          <div class="payment-history__card">
            <!--            <img src="/static/images/vsd.png" class="payment-history__card-icon" alt="Master Card" />-->
            <span class="payment-history__card-title">{{ claim.type.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="account-page" v-else>
      <div class="payment-history__list">Вы еще не покупали ничего</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountPaymentHistory",
  computed: {
    claims() {
      return this.$store.state.tmp.claims;
    },
  },
};
</script>

<style lang="stylus">
.payment-history {
  background: var(--white);
  border: 1px solid var(--border_color);
  box-sizing: border-box;
  border-radius: 15px;
  padding 20px
  display grid

  &__list {
    display grid
    grid-gap 20px
  }

  &__school {
    display grid
    grid-template-columns 25px auto
    align-items center
    grid-gap 10px

    &-image {
      width 25px
      height 25px
      border-radius 100%
      object-fit cover
      object-position center
    }

    &-title {
      font-weight: normal;
      font-size: 1em;
      line-height: 130%;
      color: var(--black);
    }
  }

  &__title {
    display flex
    width 100%
    justify-content space-between
    align-items center
    font-weight: normal;
    font-size: 1em;
    line-height: 130%;
    color: var(--black);
    margin 10px 0 15px
    +below(420px) {
      flex-direction column
      align-items flex-start
      gap 8px
    }
  }

  &__footer {
    display flex
    justify-content space-between
    align-items center
    padding-top 20px
    border-top 1px solid var(--border_color)
    +below(420px) {
      flex-direction column
      align-items flex-start
      gap 8px
    }
  }

  &__date {
    font-weight: normal;
    font-size: 1em;
    line-height: 19px;
    color: var(--black);
    opacity: 0.3;
  }

  &__card {
    display flex
    align-items center
    gap 5px

    &-icon {
      width 24px
      height 24px
      object-fit cover
      object-position center
    }

    &-title {
      font-weight: normal;
      font-size: 1em;
      line-height: 19px;
      color: var(--black);
    }
  }
}
</style>
