<template>
  <div class="cropper">
    <div class="cropper__header">
      <h3 class="cropper__title">Обрезка изображения</h3>
      <button class="cropper__close" v-if="!loading" type="button" @click="$emit('close')">
        <CloseIcon />
      </button>
    </div>
    <div class="cropper__body">
      <div v-if="data.imageBase64">
        <vue-cropper
          class="cropper__container"
          :viewMode="1"
          ref="cropper"
          :src="data.imageBase64"
          alt="Изображение"
        />
        <div class="cropper__buttons" v-if="!loading">
          <button class="btn btn--small btn--main-outline" type="button" @click="$refs.cropper.move(10, 0)">
            <CropperLeftIcon />
          </button>
          <button class="btn btn--small btn--main-outline" type="button" @click="$refs.cropper.move(0, 10)">
            <CropperUpIcon />
          </button>
          <button class="btn btn--small btn--main-outline" type="button" @click="$refs.cropper.move(0, -10)">
            <CropperDownIcon />
          </button>
          <button class="btn btn--small btn--main-outline" type="button" @click="$refs.cropper.move(-10, 0)">
            <CropperRightIcon />
          </button>
          <button class="btn btn--small btn--main-outline" type="button" @click="$refs.cropper.rotate(-45)">
            <CropperRotateLeftIcon />
          </button>
          <button class="btn btn--small btn--main-outline" type="button" @click="$refs.cropper.rotate(45)">
            <CropperRotateRightIcon />
          </button>
          <button class="btn btn--small btn--main-outline" type="button" @click="scaleHorizontal">
            <CropperFlipHorizontalIcon />
          </button>
          <button class="btn btn--small btn--main-outline" type="button" @click="scaleVertical">
            <CropperFlipVerticalIcon />
          </button>
          <button
            class="btn btn--small btn--main-outline"
            type="button"
            @click="$refs.cropper.relativeZoom(0.1)"
          >
            <CropperZoomInIcon />
          </button>
          <button
            class="btn btn--small btn--main-outline"
            type="button"
            @click="$refs.cropper.relativeZoom(-0.1)"
          >
            <CropperZoomOutIcon />
          </button>
          <button class="btn btn--small btn--main-outline" type="button" @click="$refs.cropper.reset()">
            <CropperResetIcon />
          </button>
        </div>
      </div>
    </div>
    <div class="cropper__footer">
      <button class="btn btn--main btn--medium" type="button" @click="cropImage">
        <LoadingIndicator title="Обработка" v-if="loading" />
        <template v-else>Обрезать</template>
      </button>
    </div>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import CropperLeftIcon from "components/svg/CropperLeftIcon.vue";
import CropperUpIcon from "components/svg/CropperUpIcon.vue";
import CropperDownIcon from "components/svg/CropperDownIcon.vue";
import CropperRightIcon from "components/svg/CropperRightIcon.vue";
import CropperRotateLeftIcon from "components/svg/CropperRotateLeftIcon.vue";
import CropperRotateRightIcon from "components/svg/CropperRotateRightIcon.vue";
import CropperFlipHorizontalIcon from "components/svg/CropperFlipHorizontalIcon.vue";
import CropperFlipVerticalIcon from "components/svg/CropperFlipVerticalIcon.vue";
import CropperZoomInIcon from "components/svg/CropperZoomInIcon.vue";
import CropperZoomOutIcon from "components/svg/CropperZoomOutIcon.vue";
import CropperResetIcon from "components/svg/CropperResetIcon.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";
import CloseIcon from "components/svg/CloseIcon.vue";
import UPLOAD_CROP from "gql/mutations/UploadCrop.graphql";

export default {
  name: "CropImageComponent",
  props: {
    data: Object,
    callback: Function,
  },
  components: {
    CloseIcon,
    VueCropper,
    LoadingIndicator,
    CropperResetIcon,
    CropperZoomOutIcon,
    CropperZoomInIcon,
    CropperFlipVerticalIcon,
    CropperFlipHorizontalIcon,
    CropperRotateRightIcon,
    CropperRotateLeftIcon,
    CropperRightIcon,
    CropperDownIcon,
    CropperUpIcon,
    CropperLeftIcon,
  },
  data() {
    return {
      loading: false,
      scaleH: -1,
      scaleV: -1,
    };
  },
  methods: {
    /**
     * Отражение по горизонтали
     */
    scaleHorizontal() {
      this.$refs.cropper.scaleX(this.scaleH);
      this.scaleH = this.scaleH > 0 ? -1 : 1;
    },
    /**
     * Отражение по вертикали
     */
    scaleVertical() {
      this.$refs.cropper.scaleY(this.scaleV);
      this.scaleV = this.scaleV > 0 ? -1 : 1;
    },
    /**
     * Обрезка изображения
     */
    cropImage() {
      if (!this.loading) {
        this.loading = true;
        let variables = {
          image: this.data.image,
        };
        let crop = this.$refs.cropper.getData();
        Object.keys(crop).forEach((key) => {
          variables[key] = parseInt(crop[key]);
        });
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: UPLOAD_CROP,
            variables: variables,
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.cookies["apollo-token"],
              },
            },
          })
          .then(({ data }) => {
            this.loading = false;
            this.callback(data);
            this.$emit("close");
          })
          .catch(() => {
            this.$notify({
              title: "Произошла ошибка",
              text: "При обрезке изображения произошла ошибка \n Перезагрузите страницу и попробуйте еще раз",
              type: "error",
            });
            this.$emit("close");
          });
      }
    },
  },
};
</script>
<style lang="stylus">
@import "~cropperjs/dist/cropper.css";
.cropper {
  max-width: 588px;
  width 100%
  z-index 2
  background: var(--white);
  box-shadow: 0 16px 24px var(--black_o06), 0px 2px 6px var(--black_o04), 0px 0px 1px var(--black_o04);
  border-radius: var(--radius);
  display grid
  grid-gap 20px

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 25px;
    padding-top 20px
  }

  &__title {
    font-size 1.375em
    font-weight: 500;
    margin: 0;
  }

  &__close {
    background none
    padding 0
    border 0
    cursor pointer
    absolute right 25px top 20px

    .icon {
      width 28px
      height 28px

      svg {
        width 100%
        height 100%
      }
    }
  }

  &__footer
  &__buttons {
    display flex
    gap 5px
    padding 0 25px
  }

  &__buttons {
    padding-top 20px
  }

  &__footer {
    padding-bottom 20px
  }
}
</style>
