<template>
  <figure class="icon">
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.1663 23.75L17.0837 25L15 23.75L12.9163 25L10.8337 23.75L8.75 25V8.75H21.25V25L19.1663 23.75Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 12.5H17.5V16.25H12.5V12.5Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 20H17.5"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.25 13.75H23.75C25.1313 13.75 26.25 12.4712 26.25 10.8925V6.6075C26.25 5.02875 25.1313 3.75 23.75 3.75H6.25C4.86875 3.75 3.75 5.02875 3.75 6.6075V10.8938C3.75 12.4713 4.86875 13.75 6.25 13.75H8.75"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "PaymentsIcon",
};
</script>
