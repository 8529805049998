<template>
  <div class="account-aside__container" :class="{ 'account-aside__container--show': show }">
    <div class="account-aside">
      <a
        class="account-aside__link"
        :class="{
          'account-aside__link--active': i === page,
        }"
        href="#"
        @click.prevent="$emit('change', i)"
        v-for="(p, i) in pages"
        :key="i"
      >
        <Component :is="p.icon" />
        <span>{{ p.title }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountAside",
  props: {
    show: {
      type: Boolean,
    },
    page: {
      type: Number,
    },
    pages: {
      type: Array,
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.account-aside {
  display grid
  padding-bottom 100px

  &__container {
    border-right 1px solid var(--border_color)
    height 100%
    +below(768px) {
      &:not(&--show) {
        display none
      }
    }
  }

  &__link {
    display grid
    align-items center
    grid-template-columns 30px auto
    width 100%
    grid-gap 20px
    font-weight normal
    font-size 1.125em
    line-height 22px
    color var(--black)
    padding 20px 0
    box-sizing content-box
    border-right 1px solid transparent

    .icon {
      width 30px
      height 30px

      svg {
        transition var(--transition)
        width 100%
        height 100%
        svg(var(--black))
      }
    }

    &:hover
    &--active {
      color var(--main_color)

      .icon svg {
        svg(var(--main_color))
      }
    }

    &--active {
      border-color var(--main_color)

      &:hover {
        border-color var(--main_color)
      }
    }
  }
}
</style>
