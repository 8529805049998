<template>
  <div
    class="select"
    :class="{
      'select--input': inputState === inputStates.FOCUS,
      'select--list': dropdownState === dropdownStates.BOTTOM,
      'select--multiple': multiple,
      'select--loading': loading,
      'select--error': error,
      'select--dark': dark,
      'select--list-top': dropdownState === dropdownStates.TOP,
      'select--no-title': !title,
    }"
    v-click-outside="blurInput"
  >
    <span
      class="select__selected"
      :class="{
        'select__selected--one': selectedOptions.length === 1,
      }"
      v-show="!loading"
      v-if="multiple && selectedOptions && selectedOptions.length"
    >
      <a href="#" @click.prevent="select(selectedOptions[0])" class="select__selected-item">
        <span>{{ cutString(getName(selectedOptions[0]), 12) }}</span>
        <CloseIcon />
      </a>
      <a href="#" @click.prevent="focusInput" class="select__selected-item" v-if="selectedOptions.length > 1">
        <span>...</span>
      </a>
    </span>
    <div class="select__field-container" v-show="!loading">
      <span class="select__title" @click="focusInput" v-if="title && !search">
        {{ computedTitle }}
      </span>
      <input @focus="focusInput" ref="input" class="select__field" v-model="search" />
    </div>
    <button
      type="button"
      class="select__clear"
      v-if="!multiple && !searchEmpty && clearable"
      @click="clean"
      v-show="!loading"
    >
      <CloseIcon />
    </button>
    <button type="button" class="select__action" tabindex="-1" @click="toggle" v-show="!loading">
      <DownIconSmall />
    </button>
    <SelectListComponent
      ref="list"
      v-show="!loading"
      :options="filteredOptions"
      :selected="selectedOptions ? selectedOptions : value"
      v-if="dropdownState !== dropdownStates.CLOSED"
      :multiple="multiple"
      :labelName="labelName"
      :clearable="clearable"
      @select="select"
      @clean="clean"
    />
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import SelectListComponent from "./components/SelectListComponent.vue";
import CloseIcon from "components/svg/CloseIcon.vue";
import DownIconSmall from "components/svg/DownIconSmall.vue";

export default {
  name: "SelectComponent",
  props: {
    title: {
      type: String,
    },
    labelName: {
      type: String,
      default() {
        return "name";
      },
    },
    clearable: {
      type: Boolean,
      default() {
        return true;
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: Boolean,
    multiple: Boolean,
    dark: Boolean,
    error: Boolean,
    value: [Number, String, Array, Object],
  },
  data() {
    return {
      selected: [],
      search: "",
      listTop: false,
      scrollTimeout: undefined,
      sbOptions: {
        minScrollbarLength: 20,
      },
      dropdownStates: {
        CLOSED: 0,
        TOP: 1,
        BOTTOM: 2,
      },
      inputStates: {
        BLUR: 0,
        FOCUS: 1,
      },
      dropdownState: null,
      inputState: null,
    };
  },
  computed: {
    searchEmpty() {
      return !(this.search && this.search.length);
    },
    computedTitle() {
      let title = "";
      if (this.inputState === this.inputStates.FOCUS) {
        title += this.title;
      } else {
        title += this.title.substr(0, this.stringLimit);
        if (this.title.length > this.stringLimit) {
          title += "...";
        }
      }
      return title;
    },
    stringLimit() {
      if (this.multiple) {
        return this.selectedOptions.length ? 16 : 30;
      }
      return 30;
    },
    selectedOptions() {
      if (this.value && this.value instanceof Array) {
        // Сделано так чтобы сохранять последовательность выбора
        return this.value
          .map((v) => {
            const option = this.options.find((o) => JSON.stringify(o) === JSON.stringify(v));
            if (option) {
              return option;
            }
            return false;
          })
          .filter((v) => v)
          .reverse();
        // return this.options.filter((o) => this.value.includes(o.id));
      }
      return false;
    },
    filteredOptions() {
      if (this.options.length) {
        return this.options.filter((o) => this.getName(o) && this.getName(o).includes(this.search));
      }
      return [];
    },
  },
  watch: {
    value(v) {
      if (!this.multiple) {
        this.select(v);
      }
    },
    searchEmpty(v) {
      if (!v && this.multiple) {
        this.focusInput();
      }
    },
  },
  created() {
    // Изначальное значение выпадаюшего списка
    this.dropdownState = this.dropdownStates.CLOSED;
  },
  mounted() {
    if (this.value) {
      this.inputState = this.inputStates.FOCUS;
    }
    this.showInSearch();
    // Положение выпадающего списка реагирует на скролл страницы
    window.addEventListener("scroll", () => {
      if (this.dropdownState !== this.dropdownStates.CLOSED) {
        this.openDrop();
      }
    });
  },
  beforeDestroy() {
    // Удаляю слушатель скролла страницы при уничтожении элемента
    window.removeEventListener("scroll", () => {});
  },
  methods: {
    // Если не multiple, показываю в input
    showInSearch() {
      if (this.value !== null && !this.multiple) {
        const option = this.options.find((o) => JSON.stringify(o) === JSON.stringify(this.value));
        if (option) {
          this.search = this.getName(option) || "";
        }
      }
    },
    focusInput() {
      if (document.activeElement !== this.$refs.input) {
        this.$refs.input.focus();
        return;
      }
      this.search = "";
      this.inputState = this.inputStates.FOCUS;
      this.openDrop();
    },
    blurInput() {
      this.showInSearch();
      if (this.multiple) {
        this.search = "";
        this.$refs.input.blur();
        this.inputState = this.inputStates.BLUR;
        this.closeDrop();
      } else {
        this.$refs.input.blur();
        if (this.searchEmpty) {
          this.inputState = this.inputStates.BLUR;
        }
        this.closeDrop();
      }
    },
    toggle() {
      // Переключение состояния Input
      if (this.inputState !== this.inputStates.BLUR) {
        this.blurInput();
      } else {
        this.focusInput();
      }
    },
    openDrop() {
      if (this.$refs.input) {
        const height = 200; // Минимум пространства
        const bottom = this.$refs.input.getBoundingClientRect().bottom;
        if (bottom + height > window.innerHeight) {
          this.dropdownState = this.dropdownStates.TOP;
          return;
        }
        this.dropdownState = this.dropdownStates.BOTTOM;
      }
    },
    closeDrop() {
      this.dropdownState = this.dropdownStates.CLOSED;
    },
    /**
     * Обработка события выбора в списке
     */
    select(e) {
      // Если несколько элементов
      if (this.multiple) {
        // Фокусируюсь обратно на поле поиска
        this.focusInput();
        // Открепляю от реактивного массива уже выбранных value
        let value = JSON.parse(JSON.stringify(this.value || [])).map((v) => JSON.stringify(v));
        // Если уже выбран - удаляю т.е. toggle
        // иначе заношу в конец массива выбранных
        if (value.includes(JSON.stringify(e))) {
          value.splice(value.indexOf(JSON.stringify(e)), 1);
        } else {
          value.push(JSON.stringify(e));
        }
        this.$emit(
          "input",
          // Не превращенные в string JSON объекты превращаю обратно
          // и делаю массив полностью из json
          value.map((v) => {
            if (typeof v === "string") {
              return JSON.parse(v);
            }
            return v;
          })
        );
        return;
      }
      // Если один элемент
      // Вставляю в Input label выбранного объекта
      this.search = e ? this.getName(e) : "";
      // Сравниваю 2 stringify JSON строки
      // если не равна уже выбранной
      // если выбрано "развибирать нельзя"
      // повторяю поведение обычного select
      if (JSON.stringify(this.value) !== JSON.stringify(e)) {
        this.$emit("input", e);
      }
      // Закрываю выпадающий список
      this.blurInput();
    },
    /**
     * Получение label объекта
     * @param option
     * @returns {*}
     */
    getName(option) {
      if (this.labelName) {
        return option[this.labelName];
      }
      return option;
    },
    cutString(string, size) {
      return string.substr(0, size).trim() + (string.length > size ? ".." : "");
    },
    clean() {
      if (this.multiple) {
        this.$emit("input", []);
        return;
      }
      this.$emit("input", null);
      this.search = "";
    },
  },
  components: {
    DownIconSmall,
    CloseIcon,
    SelectListComponent,
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/elements/input.styl"
@import "~@/styles/mixins/svg.styl"
.select {
  @extends .input
  transition 0s

  &--loading {
    background-color var(--border_color);
    background: linear-gradient(90deg, var(--white) 0%, var(--main_color_o10) 50%, var(--white) 100%);
    background-size: 400% 100%;
    animation: gradient 2s ease infinite;
  }

  &--error {
    border-color var(--error_red)

    .select__field {
      color: var(--error_red)
    }
  }

  &--no-title {
    .select__field {
      padding 12px 16px
    }
  }

  &--list {
    border-radius var(--radius) var(--radius) 0 0

    .select__action {
      z-index 11

      .icon {
        transform rotate(180deg)
      }
    }

    .select__clear {
      z-index 12
    }
  }

  &--list-top {
    border-radius 0 0 var(--radius) var(--radius)

    .select__action {
      z-index 11

      .icon {
        transform rotate(180deg)
      }
    }

    .select__list {
      border-radius var(--radius) var(--radius) 0 0
      absolute top left
      bottom initial
      transform translateY(calc(-100% + 1px))
    }

    .select__clear {
      z-index 12
    }
  }

  &__clear {
    @extend .select__action
    transform translateX(-50%)
    z-index 3
  }

  &__field {
    padding 25px 20px
    z-index 1

    &-container {
      position relative
      width 100%
    }
  }

  &__selected {
    display grid
    grid-gap 4px
    grid-template-columns auto auto
    margin-left 20px
    +below(480px) {
      display none
    }

    &--one {
      grid-template-columns auto
    }

    .icon {
      width 12px
      height 12px
      display flex
      align-items center
      justify-content center
    }

    &-item {
      cursor pointer
      background var(--main_color)
      border-radius 6px
      padding 6px 12px
      font-size: 0.8750em;
      line-height: 16px;
      text-align: center;
      color: var(--white);
      text-decoration none

      &:hover {
        color var(--white)
      }

      &:active
      &:focus {
        box-shadow: 0 0 0 4px var(--main_color);
      }

      &:first-child {
        display grid
        grid-template-columns auto 14px
        grid-gap 4px
        align-items center

        span {
          display flex
        }

        .icon {
          width 14px
          height 14px

          svg(var(--white))
        }
      }
    }
  }

  &--multiple {
    display grid
    grid-gap 8px
    align-items center
    grid-template-columns auto auto
    padding-right 50px
  }

  &:not(.select--input) {
    border-color var(--border_color);

    &.select--error {
      border-color var(--error_red)

      .select__title
      .select__field {
        color: var(--error_red)
      }
    }
  }

  &--dark {
    background var(--dark)
    border-color var(--dark)

    .select__field {
      color var(--white)
    }

    .select__title {
      color var(--white)
      font-weight 500
    }

    .select__clear
    .select__action {
      figure {
        svg {
          svg(var(--white))
        }
      }
    }
  }
}
</style>
