<template>
  <label class="account-badge">
    <span class="account-badge__image-container">
      <img
        :src="$store.state._env.MEDIA_ENDPOINT + image"
        :alt="`${user.name} ${user.surname}`"
        class="account-badge__image"
        v-if="image"
      />
      <img
        :src="user.head_img | image($store.state._env.MEDIA_ENDPOINT)"
        :alt="user.head_img | image_alt"
        class="account-badge__image"
        v-else
      />
      <PenIcon />
    </span>
    <span class="account-badge__info">
      <span class="account-badge__title">{{ user.name }} {{ user.surname }}</span>
      <span class="account-badge__subtitle">{{ user.email }}</span>
    </span>
    <input type="file" accept="image/*" ref="input" v-show="false" @input="showCropperModal" />
  </label>
</template>

<script>
import PenIcon from "components/svg/PenIcon.vue";
import CropImageModal from "components/modals/components/CropImageModal.vue";

export default {
  name: "AccountBadgeComponent",
  data() {
    return {
      image: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    handleCallback(e) {
      this.$refs.input.type = "";
      this.$refs.input.type = "file";
      this.image = e.UploadCrop.url;
      let image = e.UploadCrop;
      delete image.__typename;
      delete image.type;
      this.$emit("change", e.UploadCrop);
    },
    showCropperModal(e) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.$store.state._modals.push({
          component: CropImageModal,
          options: {
            data: {
              imageBase64: reader.result,
              image: e.target.files[0],
            },
            callback: this.handleCallback,
          },
        });
      };
      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
    },
  },
  components: { PenIcon },
};
</script>

<style lang="stylus">
.account-badge {
  background: var(--white);
  border: 1px dashed var(--border_color);
  box-sizing: border-box;
  border-radius: 15px;
  padding 20px
  display grid
  grid-template-columns 100px auto
  grid-gap 20px
  align-items center
  cursor pointer
  +below(420px) {
    grid-template-columns 1fr
  }

  &__info {
    display grid
    grid-gap 10px
    +below(420px) {
      display none
    }
  }

  &__image {
    border-radius 100%
    width 100%
    height 100%
    object-fit cover
    object-position center

    &-container {
      width 100px
      height 100px
      position relative
      box-sizing border-box
      border-radius 100%
      border 3px solid var(--main_color)
      +below(420px) {
        margin auto
      }

      .icon {
        absolute right -6px top -6px
        background var(--main_color)
        border-radius 100%
        border 3px solid var(--white)
        box-sizing border-box
        display inline-flex
        justify-content center
        align-items center
        flex-shrink 0
        padding 5px

        svg {
          width 14px
          height 14px
        }
      }
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.5em;
    line-height: 29px;
    color: var(--black);
  }

  &__subtitle {
    font-weight: normal;
    font-size: 1em;
    line-height: 19px;
    color: var(--black);
  }
}
</style>
