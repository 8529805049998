<template>
  <figure class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 7H11C9.4087 7 7.88258 7.63214 6.75736 8.75736C5.63214 9.88258 5 11.4087 5 13C5 14.5913 5.63214 16.1174 6.75736 17.2426C7.88258 18.3679 9.4087 19 11 19H20V21H11C8.87827 21 6.84344 20.1571 5.34315 18.6569C3.84285 17.1566 3 15.1217 3 13C3 10.8783 3.84285 8.84344 5.34315 7.34315C6.84344 5.84285 8.87827 5 11 5H16V1L22 6L16 11V7Z"
        fill="white"
      ></path>
    </svg>
  </figure>
</template>

<script>
export default {
  name: "CropperRotateRightIcon",
};
</script>
