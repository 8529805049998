<template>
  <figure class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 16L6 10H18L12 16Z" fill="white"></path>
    </svg>
  </figure>
</template>

<script>
export default {
  name: "CropperDownIcon",
};
</script>
