<template>
  <div class="account-page">
    <AccountBadgeComponent @change="handleImageUpload" />
    <span class="account-page__title">Контактная информация</span>
    <div class="input-list">
      <InputComponent
        v-model.trim="form.name.value"
        :errors="form.name.errors"
        :messages="form.name.messages"
        :title="form.name.title"
      />
      <InputComponent
        v-model.trim="form.surname.value"
        :errors="form.surname.errors"
        :messages="form.surname.messages"
        :title="form.surname.title"
      />
      <InputComponent
        v-model.trim="form.email.value"
        :errors="form.email.errors"
        :messages="form.email.messages"
        :title="form.email.title"
        :type="form.email.type"
      />
      <InputComponent
        v-model.trim="form.phone.value"
        :errors="form.phone.errors"
        :messages="form.phone.messages"
        :title="form.phone.title"
        :mask="form.phone.mask"
      />
      <div class="input-list">
        <InputComponent
          v-model.trim="form.day.value"
          :errors="form.day.errors"
          :messages="form.day.messages"
          :title="form.day.title"
          :mask="form.day.mask"
          ref="birthDay"
          type="number"
          :min="1"
          :max="selectedBirthDateMonthLastDay"
        />
        <SelectComponent
          v-model="form.month.value"
          :errors="form.month.errors"
          :messages="form.month.messages"
          :label-name="form.month.label"
          @input="fixDate"
          :title="form.month.title"
          :clearable="false"
          :options="$store.state.months"
        />
        <InputComponent
          v-model.trim="form.year.value"
          :errors="form.year.errors"
          :messages="form.year.messages"
          :title="form.year.title"
          :mask="form.year.mask"
          @input="fixDate"
          type="number"
          :min="currentYear - 70"
          :max="currentYear - 10"
        />
      </div>
    </div>
    <button type="button" class="btn btn--main" @click="submitPersonalData" :disabled="personalDataLoading">
      <LoadingIndicator title="Обновление данных" v-if="personalDataLoading" />
      <template v-else>Сохранить изменения</template>
    </button>
    <span class="account-page__title">Конфиденциальность</span>
    <div class="input-list">
      <InputComponent
        v-model.trim="passwordForm.current_password.value"
        :errors="passwordForm.current_password.errors"
        :messages="passwordForm.current_password.messages"
        :title="passwordForm.current_password.title"
        :type="passwordForm.current_password.show ? 'text' : 'password'"
        @submit="passwordForm.current_password.show = !passwordForm.current_password.show"
      >
        <template v-slot:action="">
          <EyeCloseIcon v-if="passwordForm.current_password.show" />
          <EyeIcon v-else />
        </template>
      </InputComponent>
      <InputComponent
        v-model.trim="passwordForm.password.value"
        :errors="passwordForm.password.errors"
        :messages="passwordForm.password.messages"
        :title="passwordForm.password.title"
        :type="passwordForm.password.show ? 'text' : 'password'"
        @submit="passwordForm.password.show = !passwordForm.password.show"
      >
        <template v-slot:action="">
          <EyeCloseIcon v-if="passwordForm.password.show" />
          <EyeIcon v-else />
        </template>
      </InputComponent>
      <InputComponent
        v-model.trim="passwordForm.password_confirmation.value"
        :errors="passwordForm.password_confirmation.errors"
        :messages="passwordForm.password_confirmation.messages"
        :title="passwordForm.password_confirmation.title"
        :type="passwordForm.password_confirmation.show ? 'text' : 'password'"
        @submit="passwordForm.password_confirmation.show = !passwordForm.password_confirmation.show"
      >
        <template v-slot:action="">
          <EyeCloseIcon v-if="passwordForm.password_confirmation.show" />
          <EyeIcon v-else />
        </template>
      </InputComponent>
    </div>
    <button
      type="button"
      class="btn btn--main"
      @click="submitPasswordChange"
      :disabled="passwordChangeLoading"
    >
      <LoadingIndicator title="Смена пароля" v-if="passwordChangeLoading" />
      <template v-else>Сохранить изменения</template>
    </button>
  </div>
</template>

<script>
import AccountBadgeComponent from "./AccountBadgeComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import SelectComponent from "components/inputs/select/index.vue";
import moment from "moment";
import USER_UPDATE from "gql/auth/UserUpdate.graphql";
import USER_PASSWORD_UPDATE from "gql/auth/UserPasswordUpdate.graphql";
import EyeCloseIcon from "components/svg/EyeCloseIcon.vue";
import EyeIcon from "components/svg/EyeIcon.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";

export default {
  name: "AccountPersonalData",
  data() {
    return {
      personalDataLoading: false,
      passwordChangeLoading: false,
      currentYear: parseInt(moment().format("YYYY")),
      form: {
        name: {
          errors: [],
          messages: [],
          title: "Имя",
          value: null,
        },
        surname: {
          errors: [],
          messages: [],
          title: "Фамилия",
          value: null,
        },
        email: {
          errors: [],
          messages: [],
          title: "Email",
          type: "email",
          value: null,
        },
        phone: {
          errors: [],
          messages: [],
          title: "Номер телефона",
          mask: "# (###) ### ## ##",
          value: null,
        },
        day: {
          errors: [],
          messages: [],
          title: "День",
          mask: "##",
          value: null,
        },
        month: {
          errors: [],
          messages: [],
          label: "title",
          title: "Месяц",
          value: [],
        },
        year: {
          errors: [],
          messages: [],
          title: "Год",
          mask: "####",
          value: null,
        },
        head_img: {
          errors: [],
          value: null,
        },
      },
      passwordForm: {
        current_password: {
          errors: [],
          messages: [],
          title: "Старый пароль",
          value: null,
          show: false,
        },
        password: {
          errors: [],
          messages: ["Пароль должен содержать минимум 8 символов"],
          title: "Новый пароль",
          value: null,
          show: false,
        },
        password_confirmation: {
          errors: [],
          messages: ["Пароли должны совпадать"],
          title: "Подтвердите новый пароль",
          value: null,
          show: false,
        },
      },
    };
  },
  computed: {
    selectedBirthDateMonthLastDay() {
      if (this.form.day.value && this.form.month.value && this.form.year.value) {
        return parseInt(
          moment(`01.${this.form.month.value.id}.${this.form.year.value}`, "DD.MM.YYYY")
            .endOf("month")
            .format("DD")
        );
      }
      return 28;
    },
    passwordChangeFormFilled() {
      return (
        this.passwordForm.current_password.value &&
        this.passwordForm.password.value &&
        this.passwordForm.password_confirmation.value
      );
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
  beforeMount() {
    this.form.name.value = this.user.name;
    this.form.surname.value = this.user.surname;
    this.form.email.value = this.user.email;
    this.form.phone.value = this.user.phone;
    if (this.user.date_birth) {
      this.form.day.value = moment(this.user.date_birth).format("DD");
      this.form.month.value = this.$store.state.months.find((m) => {
        return parseInt(m.id) === parseInt(moment(this.user.date_birth).format("MM"));
      });
      this.form.year.value = moment(this.user.date_birth).format("YYYY");
    }
  },
  methods: {
    fixDate() {
      this.$nextTick(() => {
        this.$refs.birthDay.respectBounds();
      });
    },
    handleImageUpload(event) {
      this.form.head_img.value = event;
    },
    resetErrors(form) {
      Object.keys(this[form]).forEach((key) => {
        this[form][key].errors = [];
      });
    },
    parseGqlErrors(graphQLErrors, form) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            err.extensions.validation[key].forEach((message) => {
              if (form === "form") {
                if (this[form][key.replace("input.", "")]) {
                  this[form][key.replace("input.", "")].errors.push(message);
                }
              } else {
                if (this[form][key]) {
                  this[form][key].errors.push(message);
                }
              }
            });
          });
        }
      });
    },
    submitPersonalData() {
      if (!this.personalDataLoading) {
        this.resetErrors("form");
        this.personalDataLoading = true;
        let date_birth;
        if (this.form.day.value && this.form.month.value && this.form.year.value) {
          date_birth = moment(
            `${this.form.day.value}.${this.form.month.value.id}.${this.form.year.value}`,
            "DD.MM.YYYY"
          );
        }
        if (date_birth && !date_birth.isValid()) {
          console.log(date_birth.invalidAt());
          return;
        }
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_UPDATE,
            variables: {
              input: {
                name: this.form.name.value,
                surname: this.form.surname.value,
                email: this.form.email.value,
                phone: this.form.phone.value,
                date_birth: date_birth.format("YYYY-MM-DD HH:mm:ss"),
                head_img: this.form.head_img.value,
              },
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.cookies["apollo-token"],
              },
            },
          })
          .then(({ data }) => {
            if (data && data.UserUpdate) {
              this.$store.state.auth.user = data.UserUpdate.user;
              this.$notify({
                title: "Сохранено",
                text: data.UserUpdate.message,
                duration: 3000,
                speed: 200,
                type: "success",
              });
            }
            this.personalDataLoading = false;
          })
          .catch(({ graphQLErrors }) => {
            this.personalDataLoading = false;
            this.parseGqlErrors(graphQLErrors, "form");
          });
      }
    },
    submitPasswordChange() {
      if (!this.passwordChangeLoading && this.passwordChangeFormFilled) {
        this.resetErrors("passwordForm");
        this.passwordChangeLoading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_PASSWORD_UPDATE,
            variables: {
              current_password: this.passwordForm.current_password.value,
              password: this.passwordForm.password.value,
              password_confirmation: this.passwordForm.password_confirmation.value,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.cookies["apollo-token"],
              },
            },
          })
          .then(({ data }) => {
            if (data && data.UserPasswordUpdate) {
              this.$notify({
                title: "Сохранено",
                text: data.UserPasswordUpdate.message,
                duration: 3000,
                speed: 200,
                type: "success",
              });
            }
            this.passwordChangeLoading = false;
          })
          .catch(({ graphQLErrors }) => {
            this.passwordChangeLoading = false;
            this.parseGqlErrors(graphQLErrors, "passwordForm");
          });
      }
    },
  },
  components: {
    LoadingIndicator,
    EyeIcon,
    EyeCloseIcon,
    SelectComponent,
    InputComponent,
    AccountBadgeComponent,
  },
};
</script>

<style lang="stylus">
.input-list {
  display flex
  flex-direction column
  gap 20px
  width 100%

  & > * {
    width 100%
  }

  & > .input-list {
    flex-direction row
    gap 5px
    +below(420px) {
      flex-direction column
      gap 20px
    }
  }
}
</style>
