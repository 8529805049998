<template>
  <main class="main account">
    <div class="account-header">
      <h1 class="account-header__title">Личный кабинет</h1>
    </div>
    <div class="account__container">
      <AccountAside @change="setPage" :show="page === null" :pages="pages" :page="page" />
      <div class="account__content" v-if="page !== null">
        <template v-if="$store.state.auth.user">
          <a @click.prevent="page = null" class="account__back-btn">
            <ArrowRightIcon />
            <span>{{ pages[page].title }}</span>
          </a>
          <Transition mode="out-in" name="page">
            <Component :is="pages[page].view" />
          </Transition>
        </template>
        <LoadingIndicator title="Загрузка" v-else />
      </div>
    </div>
  </main>
</template>

<script>
import AccountAside from "./components/AccountAside.vue";
import UserProfileIcon from "components/svg/UserProfileIcon.vue";
import PaymentsIcon from "components/svg/PaymentsIcon.vue";
import AccountPaymentHistory from "./components/AccountPaymentHistory.vue";
import AccountPersonalData from "./components/AccountPersonalData.vue";
import ArrowRightIcon from "components/svg/ArrowRightIcon.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";
import MY_CLAIMS from "gql/auth/my_claims.graphql";

export default {
  name: "AccountPage",
  async asyncData({ apollo, store }) {
    // Здесь грузится только история покупок т.к. личные данные грузятся в App.vue
    // без этих данных вход на эту страницу невозможен/не имеет смысла
    apollo.defaultClient
      .query({
        query: MY_CLAIMS,
        context: {
          headers: {
            Authorization: "Bearer " + store.state.cookies["apollo-token"],
          },
        },
      })
      .then(({ data }) => {
        store.state.tmp.claims = data.my_claims;
      })
      .catch(() => {
        store.state.tmp.haveError = true;
      });
  },
  data() {
    return {
      page: 0,
      pages: [
        {
          title: "Мои данные",
          icon: UserProfileIcon,
          view: AccountPersonalData,
        },
        {
          title: "История покупок",
          icon: PaymentsIcon,
          view: AccountPaymentHistory,
        },
      ],
    };
  },
  mounted() {
    if (this.$store.state.tmp.haveError) {
      this.$notify({
        title: "Ошибка",
        text: "Во время загрузки данных произошла ошибка, попробуйте перезагрузить страницу",
        duration: 8000,
        speed: 200,
        type: "error",
      });
    }
  },
  methods: {
    setPage(index) {
      this.page = index;
    },
  },
  components: {
    LoadingIndicator,
    ArrowRightIcon,
    AccountPersonalData,
    AccountPaymentHistory,
    AccountAside,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.account {
  background var(--white)
  display grid
  grid-gap 50px
  width 100%

  &__container {
    max-width var(--account_width)
    width 100%
    margin 0 auto
    display grid
    grid-template-columns 270px auto
    grid-gap 20px
    align-items start
    padding 0 32px
    box-sizing content-box
    +below(960px) {
      box-sizing border-box
    }
    +below(768px) {
      grid-template-columns 1fr
    }
    +below(640px) {
      padding 0 20px
    }
  }

  &__content {
    max-width 590px
    width 100%
    display grid
    align-items start
    padding-bottom 100px
    +below(768px) {
      max-width 100%
      padding-bottom 50px
    }

    > .loading-indicator {
      font-size 1.125em
      font-weight 500
      padding 20px 0
      align-self flex-start
      justify-self flex-start
    }

  }

  &__back-btn {
    width 100%
    display flex
    align-items center
    gap 10px
    font-weight: 500;
    font-size: 1.125em;
    line-height: 22px;
    color: var(--black);
    margin 20px 0
    +above(769px) {
      display none
    }

    .icon {
      width 30px
      height 30px
      transform rotate(180deg)

      svg {
        width 100%
        height 100%
        svg(var(--black))
      }
    }
  }
}

.account-header {
  width 100%
  background var(--main_color)
  display: flex;
  flex-shrink: 0;
  justify-content center
  padding 50px 32px
  +below(768px) {
    display none
  }

  &__title {
    margin 0
    max-width var(--account_width)
    width 100%
    font-style: normal;
    font-weight: 600;
    font-size: 3em;
    line-height: 58px;
    color: var(--white);
  }
}

.account-page {
  display grid
  grid-gap 20px

  &__title {
    font-weight: 500;
    font-size: 1.5em;
    line-height: 29px;
    color: var(--black);
    margin-top 10px
  }

  .btn {
    margin-right auto

    &:not(:last-of-type) {
      margin-bottom 10px
    }
  }
}
</style>
