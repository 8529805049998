<template>
  <figure class="icon">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.91399 12.8H3.2002V10.0862C3.20017 9.94849 3.22728 9.8121 3.27999 9.68485C3.3327 9.5576 3.40998 9.44199 3.5074 9.34462L9.94419 2.90782C10.0416 2.81027 10.1572 2.73288 10.2845 2.68008C10.4118 2.62728 10.5483 2.6001 10.6861 2.6001C10.8239 2.6001 10.9604 2.62728 11.0877 2.68008C11.215 2.73288 11.3306 2.81027 11.428 2.90782L13.0924 4.57222C13.1899 4.66958 13.2673 4.78522 13.3201 4.91253C13.3729 5.03983 13.4001 5.1763 13.4001 5.31412C13.4001 5.45194 13.3729 5.58841 13.3201 5.71571C13.2673 5.84302 13.1899 5.95866 13.0924 6.05602L6.65559 12.4928C6.4588 12.6893 6.19211 12.7998 5.91399 12.8Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.59961 4.40002L11.5996 7.40002"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M6.2002 9.8L8.0002 8" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "PenIcon",
};
</script>
