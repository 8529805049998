<template>
  <figure class="icon">
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0002 8.75C16.5339 8.75 17.7777 9.99375 17.7777 11.5275C17.7777 13.0613 16.5339 14.305 15.0002 14.305C13.4664 14.305 12.2227 13.0625 12.2227 11.5275C12.2227 9.9925 13.4664 8.75 15.0002 8.75Z"
        stroke="#007385"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25 14.0526C25 19.5126 21.0538 24.6301 15.65 26.1601C15.2275 26.2801 14.7725 26.2801 14.35 26.1601C8.94625 24.6313 5 19.5126 5 14.0526V9.01755C5 8.00255 5.61375 7.08755 6.55375 6.7038L12.6338 4.2163C14.1513 3.59505 15.8512 3.59505 17.3675 4.2163L23.4475 6.7038C24.3862 7.08755 25 8.00255 25 9.01755V14.0526Z"
        stroke="#007385"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 18.7501C19.855 18.3839 19.6288 18.0564 19.34 17.7889C18.8513 17.3364 18.2138 17.0839 17.5475 17.0839C16.4363 17.0839 13.565 17.0839 12.4538 17.0839C11.7875 17.0839 11.1487 17.3364 10.66 17.7889C10.3712 18.0564 10.1462 18.3839 10 18.7501"
        stroke="#007385"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "UserProfileIcon",
};
</script>
