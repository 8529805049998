<template>
  <figure class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 12L14 6V18L8 12Z" fill="white"></path>
    </svg>
  </figure>
</template>

<script>
export default {
  name: "CropperLeftIcon",
};
</script>
